<template>
  <div class="pt-2">
    <h3 class="text-center text--disabled">Nenhuma tarefa Adicionada</h3>
  </div>
</template>

<script>
export default {
  name: 'Tasks'
}
</script>
